import React, { useEffect, useState } from "react";
import supportImage from "../assets/community.png";
import backImage from "../assets/webbackground.png";
import arrowImage from "../assets/ArrowButton.png";
import arrowImageL from "../assets/ArrowButtonR.png";
import h1Image from "../assets/storeH2.png";
import h2Image from "../assets/storeH5.png";
import h1Gif from "../assets/ktkgif5.gif";
import h2Gif from "../assets/combat2.gif";
import h4Image from "../assets/storeH4.png";
import h3Image from "../assets/ktkh6.png";
import h4Gif from "../assets/buffs.gif";
import h3Gif from "../assets/ktkgif6.gif";
import h5Image from "../assets/ktkh7.png";
import h5Gif from "../assets/ktkgif7.gif";
import h6Image from "../assets/ktkh7.png";
import h6Gif from "../assets/ktkgif8.gif";
import carNav from "../assets/carouselNav2.png";
import info1 from "../assets/ktkinfo1.png";
import info2 from "../assets/ktkinfo2.png";
import info3 from "../assets/ktkinfo3.png";
import info4 from "../assets/ktkinfo4.png";
import info5 from "../assets/ktkinfo5.png";
import esinfo1 from "../assets/esinfo1.png";
import esinfo2 from "../assets/esinfo2.png";
import esinfo3 from "../assets/esinfo3.png";
import esinfo4 from "../assets/esinfo4.png";
import esinfo5 from "../assets/esinfo5.png";
import infoico from "../assets/infoicon.png";
import naviconL from "../assets/naviconL.png";
import naviconL2 from "../assets/naviconL2.png";
import naviconR from "../assets/naviconR.png";
import naviconR2 from "../assets/naviconR2.png";
import naviconC from "../assets/naviconC.png";
import naviconC2 from "../assets/naviconC2.png";

import frinfo1 from "../assets/frinfo1.png";
import frinfo2 from "../assets/frinfo2.png";
import frinfo3 from "../assets/frinfo3.png";
import frinfo4 from "../assets/frinfo4.png";
import frinfo5 from "../assets/frinfo5.png";

import deinfo1 from "../assets/deinfo1.png";
import deinfo2 from "../assets/deinfo2.png";
import deinfo3 from "../assets/deinfo3.png";
import deinfo4 from "../assets/deinfo4.png";
import deinfo5 from "../assets/deinfo5.png";

import ptinfo1 from "../assets/ptinfo1.png";
import ptinfo2 from "../assets/ptinfo2.png";
import ptinfo3 from "../assets/ptinfo3.png";
import ptinfo4 from "../assets/ptinfo4.png";
import ptinfo5 from "../assets/ptinfo5.png";

import zhinfo1 from "../assets/zhinfo1.png";
import zhinfo2 from "../assets/zhinfo2.png";
import zhinfo3 from "../assets/zhinfo3.png";
import zhinfo4 from "../assets/zhinfo4.png";
import zhinfo5 from "../assets/zhinfo5.png";

import jainfo1 from "../assets/jainfo1.png";
import jainfo2 from "../assets/jainfo2.png";
import jainfo3 from "../assets/jainfo3.png";
import jainfo4 from "../assets/jainfo4.png";
import jainfo5 from "../assets/jainfo5.png";

import koinfo1 from "../assets/koinfo1.png";
import koinfo2 from "../assets/koinfo2.png";
import koinfo3 from "../assets/koinfo3.png";
import koinfo4 from "../assets/koinfo4.png";
import koinfo5 from "../assets/koinfo5.png";

import esh1 from "../assets/esh1.png";
import esh2 from "../assets/esh2.png";
import esh3 from "../assets/esh3.png";
import esh4 from "../assets/esh4.png";
import esh5 from "../assets/ktkh7.png";
import esh6 from "../assets/esh1.png";

import frh1 from "../assets/frh1.png";
import frh2 from "../assets/frh2.png";
import frh3 from "../assets/frh3.png";
import frh4 from "../assets/frh4.png";
import frh5 from "../assets/ktkh7.png";
import frh6 from "../assets/frh1.png";

import deh1 from "../assets/deh1.png";
import deh2 from "../assets/deh2.png";
import deh3 from "../assets/deh3.png";
import deh4 from "../assets/deh4.png";
import deh5 from "../assets/ktkh7.png";
import deh6 from "../assets/deh1.png";

import pth1 from "../assets/pth1.png";
import pth2 from "../assets/pth2.png";
import pth3 from "../assets/pth3.png";
import pth4 from "../assets/pth4.png";
import pth5 from "../assets/ktkh7.png";
import pth6 from "../assets/pth1.png";

import zhh1 from "../assets/zhh1.png";
import zhh2 from "../assets/zhh2.png";
import zhh3 from "../assets/zhh3.png";
import zhh4 from "../assets/zhh4.png";
import zhh5 from "../assets/ktkh7.png";
import zhh6 from "../assets/zhh1.png";

import jah1 from "../assets/jah1.png";
import jah2 from "../assets/jah2.png";
import jah3 from "../assets/jah3.png";
import jah4 from "../assets/jah4.png";
import jah5 from "../assets/ktkh7.png";
import jah6 from "../assets/jah1.png";

import koh1 from "../assets/koh1.png";
import koh2 from "../assets/koh2.png";
import koh3 from "../assets/koh3.png";
import koh4 from "../assets/koh4.png";
import koh5 from "../assets/ktkh7.png";
import koh6 from "../assets/koh1.png";
import "../App.css";

const h1Images = {
  en: h1Image,
  es: esh1,
  fr: frh1,
  de: deh1,
  pt: pth1,
  zh: zhh1,
  ja: jah1,
  ko: koh1,
};
const h2Images = {
  en: h2Image,
  es: esh2,
  fr: frh2,
  de: deh2,
  pt: pth2,
  zh: zhh2,
  ja: jah2,
  ko: koh2,
};

const h3Images = {
  en: h3Image,
  es: esh3,
  fr: frh3,
  de: deh3,
  pt: pth3,
  zh: zhh3,
  ja: jah3,
  ko: koh3,
};

const h4Images = {
  en: h4Image,
  es: esh4,
  fr: frh4,
  de: deh4,
  pt: pth4,
  zh: zhh4,
  ja: jah4,
  ko: koh4,
};

const h5Images = {
  en: h5Image,
  es: esh5,
  fr: frh5,
  de: deh5,
  pt: pth5,
  zh: zhh5,
  ja: jah5,
  ko: koh5,
};

const h6Images = {
  en: h6Image,
  es: esh6,
  fr: frh6,
  de: deh6,
  pt: pth6,
  zh: zhh6,
  ja: jah6,
  ko: koh6,
};

const info1Images = {
  en: info1,
  es: esinfo1,
  fr: frinfo1,
  de: deinfo1,
  pt: ptinfo1,
  zh: zhinfo1,
  ja: jainfo1,
  ko: koinfo1,
};
const info2Images = {
  en: info2,
  es: esinfo2,
  fr: frinfo2,
  de: deinfo2,
  pt: ptinfo2,
  zh: zhinfo2,
  ja: jainfo2,
  ko: koinfo2,
};
const info3Images = {
  en: info3,
  es: esinfo3,
  fr: frinfo3,
  de: deinfo3,
  pt: ptinfo3,
  zh: zhinfo3,
  ja: jainfo3,
  ko: koinfo3,
};
const info4Images = {
  en: info4,
  es: esinfo4,
  fr: frinfo4,
  de: deinfo4,
  pt: ptinfo4,
  zh: zhinfo4,
  ja: jainfo4,
  ko: koinfo4,
};
const info5Images = {
  en: info5,
  es: esinfo5,
  fr: frinfo5,
  de: deinfo5,
  pt: ptinfo5,
  zh: zhinfo5,
  ja: jainfo5,
  ko: koinfo5,
};

function Features({ isActive, zIndex, language }) {
  const [isHoveredL, setIsHoveredL] = useState(false);
  const [isHoveredR, setIsHoveredR] = useState(false);
  const [isHoveredInfo1, setIsHoveredInfo1] = useState(false);
  const [isHoveredInfo2, setIsHoveredInfo2] = useState(false);
  const [isHoveredInfo3, setIsHoveredInfo3] = useState(false);
  const [isHoveredInfo4, setIsHoveredInfo4] = useState(false);
  const [carouselPage, setCarouselPage] = useState(1);
  const [elementsOpacity, setElementsOpacity] = useState(0);
  const [scale, setScale] = useState(1);
  const [scaleB, setScaleB] = useState(1);
  const [style, setStyle] = useState({
    opacity: 0,
    filter: "blur(10px)",
  });

  const handleMouseEnterL = () => {
    setIsHoveredL(true);
  };

  const handleMouseLeaveL = () => {
    setIsHoveredL(false);
  };

  const handleMouseEnterR = () => {
    setIsHoveredR(true);
  };

  const handleMouseLeaveR = () => {
    setIsHoveredR(false);
  };

  // State to control the style of the sliding image
  const [imageStyle, setImageStyle] = useState({
    position: "absolute",
    top: "0", // Position the image at the top of the screen
    right: "-30%", // Initially off-screen to the right
    width: "100%",
    opacity: "100%",
    height: "100vh", // Full height of the viewport
    backgroundImage: `url(${backImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "right 1s ease-out", // Smooth transition for the slide-in effect
    zIndex: zIndex - 1, // Ensure it's below modal elements but above background elements
  });

  useEffect(() => {
    const calculateScale = () => {
      const scaleValue = window.innerWidth / 1080; // Adjust based on your design needs
      setScale(scaleValue < 1 ? scaleValue : 1); // Prevent scaling above 1
    };

    calculateScale(); // Initial scale calculation
    window.addEventListener("resize", calculateScale); // Adjust scale on window resize

    return () => window.removeEventListener("resize", calculateScale); // Cleanup listener
  }, []);

  useEffect(() => {
    const calculateScaleB = () => {
      const scaleValueB = window.innerWidth / 1700; // Adjust based on your design needs
      setScaleB(scaleValueB < 1 ? scaleValueB : 1); // Prevent scaling above 1
    };

    calculateScaleB(); // Initial scale calculation
    window.addEventListener("resize", calculateScaleB); // Adjust scale on window resize

    return () => window.removeEventListener("resize", calculateScaleB); // Cleanup listener
  }, []);

  useEffect(() => {
    if (isActive) {
      setStyle({
        opacity: 1,
        filter: "blur(0px)",
        transition: "opacity 1s ease-in-out, filter 1s ease-in-out",
      });
      setImageStyle((prevState) => ({
        ...prevState,
        right: "0", // Slide in to take up 30% of the screen from the right
      }));
      setTimeout(() => {
        setElementsOpacity(1);
      }, 700);
    } else {
      setStyle({
        opacity: 0,
        filter: "blur(10px)",
        transition: "opacity 2s ease-in-out, filter 2s ease-in-out",
      });
      setImageStyle((prevState) => ({
        ...prevState,
        right: "-30%", // Slide back out to the right
      }));

      setTimeout(() => {
        setElementsOpacity(0);
      }, 700);
    }
  }, [isActive]);

  // Navigate to the next carousel page
  const goToNextPage = () => {
    setCarouselPage((prevPage) => (prevPage === 3 ? 1 : prevPage + 1));
  };

  // Navigate to the previous carousel page
  const goToPreviousPage = () => {
    setCarouselPage((prevPage) => (prevPage === 1 ? 3 : prevPage - 1));
  };

  const handleClickL = () => {
    setCarouselPage(1); // Set prevPage to 1 when the button is clicked
  };
  const handleClickC = () => {
    setCarouselPage(2); // Set prevPage to 1 when the button is clicked
  };
  const handleClickR = () => {
    setCarouselPage(3); // Set prevPage to 1 when the button is clicked
  };

  const renderCarouselContent = () => {
    switch (carouselPage) {
      case 1:
        return (
          <>
            <img
              src={h1Images[language]}
              style={{
                width: "30vw",
                marginTop: "10vh",
                marginLeft: "58vw",
                zIndex: "101",
                opacity: elementsOpacity,
                transition: "opacity 2s ease-in-out",
              }}
            />
            <div
              onMouseEnter={() => setIsHoveredInfo1(true)}
              onMouseLeave={() => setIsHoveredInfo1(false)}
              style={{
                marginTop: "0vh",
                marginLeft: "58vw",
              }}
            >
              <img
                src={h1Gif}
                style={{
                  width: "30vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={info1Images[language]}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: isHoveredInfo1 ? 1 : 0,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={infoico}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="glow-on-hoverL"
                style={{ opacity: isHoveredL ? "100%" : "60%" }}
              >
                <button
                  onClick={goToPreviousPage}
                  style={{
                    marginLeft: "53vw",
                    marginTop: "-3vh",
                    position: "absolute",
                    background: "transparent",
                    border: "none",
                    transform: `scale(${scaleB})`,
                    opacity: elementsOpacity,
                    transition: "opacity 2s ease-in-out",
                  }}
                >
                  <img
                    src={arrowImageL}
                    alt="Previous"
                    onMouseEnter={handleMouseEnterL}
                    onMouseLeave={handleMouseLeaveL}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </button>
              </div>
              <div
                className="glow-on-hoverR"
                style={{
                  opacity: isHoveredR ? "100%" : "60%",
                  cursor: "pointer",
                }}
              >
                <button
                  onClick={goToNextPage}
                  style={{
                    marginLeft: "90vw",
                    marginTop: "-3vh",
                    position: "absolute",
                    background: "transparent",
                    border: "none",
                    transform: `scale(${scaleB})`,
                    opacity: elementsOpacity,
                    transition: "opacity 2s ease-in-out",
                  }}
                >
                  <img
                    src={arrowImage}
                    alt="Next"
                    onMouseEnter={handleMouseEnterR}
                    onMouseLeave={handleMouseLeaveR}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </button>
              </div>
              <img
                src={h2Images[language]}
                style={{
                  width: "30vw",
                  marginTop: "0vh",
                  marginLeft: "58vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                }}
              />
            </div>
            <div
              onMouseEnter={() => setIsHoveredInfo2(true)}
              onMouseLeave={() => setIsHoveredInfo2(false)}
              style={{
                marginTop: "0vh",
                marginLeft: "58vw",
              }}
            >
              <img
                src={h2Gif}
                style={{
                  width: "30vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={info2Images[language]}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: isHoveredInfo2 ? 1 : 0,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={infoico}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={carNav}
                style={{
                  width: "408px",
                  height: "23px",
                  marginLeft: "58vw",
                  marginTop: "2vh",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                }}
              />
              <button
                onClick={handleClickC}
                style={{
                  backgroundImage: `url(${naviconC})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "58vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
              <button
                onClick={handleClickL}
                style={{
                  backgroundImage: `url(${naviconL2})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "51.5vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
              <button
                onClick={handleClickR}
                style={{
                  backgroundImage: `url(${naviconR})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "64.5vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <img
              src={h3Images[language]}
              style={{
                width: "30vw",
                marginTop: "10vh",
                marginLeft: "58vw",
                zIndex: "101",
                opacity: elementsOpacity,
                transition: "opacity 2s ease-in-out",
              }}
            />
            <div
              onMouseEnter={() => setIsHoveredInfo3(true)}
              onMouseLeave={() => setIsHoveredInfo3(false)}
              style={{
                marginTop: "0vh",
                marginLeft: "58vw",
              }}
            >
              <img
                src={h3Gif}
                style={{
                  width: "30vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={info3Images[language]}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: isHoveredInfo3 ? 1 : 0,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={infoico}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="glow-on-hoverL"
                style={{ opacity: isHoveredL ? "100%" : "60%" }}
              >
                <button
                  onClick={goToPreviousPage}
                  style={{
                    marginLeft: "53vw",
                    marginTop: "-3vh",
                    position: "absolute",
                    background: "transparent",
                    border: "none",
                    transform: `scale(${scaleB})`,
                    opacity: elementsOpacity,
                    transition: "opacity 2s ease-in-out",
                  }}
                >
                  <img
                    src={arrowImageL}
                    alt="Previous"
                    onMouseEnter={handleMouseEnterL}
                    onMouseLeave={handleMouseLeaveL}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </button>
              </div>
              <div
                className="glow-on-hoverR"
                style={{
                  opacity: isHoveredR ? "100%" : "60%",
                  cursor: "pointer",
                }}
              >
                <button
                  onClick={goToNextPage}
                  style={{
                    marginLeft: "90vw",
                    marginTop: "-3vh",
                    position: "absolute",
                    background: "transparent",
                    border: "none",
                    transform: `scale(${scaleB})`,
                    opacity: elementsOpacity,
                    transition: "opacity 2s ease-in-out",
                  }}
                >
                  <img
                    src={arrowImage}
                    alt="Next"
                    onMouseEnter={handleMouseEnterR}
                    onMouseLeave={handleMouseLeaveR}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </button>
              </div>
              <img
                src={h4Images[language]}
                style={{
                  width: "30vw",
                  marginTop: "0vh",
                  marginLeft: "58vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                }}
              />
            </div>
            <div
              onMouseEnter={() => setIsHoveredInfo4(true)}
              onMouseLeave={() => setIsHoveredInfo4(false)}
              style={{
                marginTop: "0vh",
                marginLeft: "58vw",
              }}
            >
              <img
                src={h4Gif}
                style={{
                  width: "30vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={info4Images[language]}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: isHoveredInfo4 ? 1 : 0,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={infoico}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={carNav}
                style={{
                  width: "408px",
                  height: "23px",
                  marginLeft: "58vw",
                  marginTop: "2vh",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                }}
              />
              <button
                onClick={handleClickC}
                style={{
                  backgroundImage: `url(${naviconC2})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "58vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
              <button
                onClick={handleClickL}
                style={{
                  backgroundImage: `url(${naviconL})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "51.5vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
              <button
                onClick={handleClickR}
                style={{
                  backgroundImage: `url(${naviconR})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "64.5vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <img
              src={h5Images[language]}
              style={{
                width: "30vw",
                marginTop: "10vh",
                marginLeft: "58vw",
                zIndex: "101",
                opacity: elementsOpacity,
                transition: "opacity 2s ease-in-out",
              }}
            />
            <div
              onMouseEnter={() => setIsHoveredInfo1(true)}
              onMouseLeave={() => setIsHoveredInfo1(false)}
              style={{
                marginTop: "0vh",
                marginLeft: "58vw",
              }}
            >
              <img
                src={h5Gif}
                style={{
                  width: "30vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={info5Images[language]}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: isHoveredInfo1 ? 1 : 0,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={infoico}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="glow-on-hoverL"
                style={{ opacity: isHoveredL ? "100%" : "60%" }}
              >
                <button
                  onClick={goToPreviousPage}
                  style={{
                    marginLeft: "53vw",
                    marginTop: "-3vh",
                    position: "absolute",
                    background: "transparent",
                    border: "none",
                    transform: `scale(${scaleB})`,
                    opacity: elementsOpacity,
                    transition: "opacity 2s ease-in-out",
                  }}
                >
                  <img
                    src={arrowImageL}
                    alt="Previous"
                    onMouseEnter={handleMouseEnterL}
                    onMouseLeave={handleMouseLeaveL}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </button>
              </div>
              <div
                className="glow-on-hoverR"
                style={{
                  opacity: isHoveredR ? "100%" : "60%",
                  cursor: "pointer",
                }}
              >
                <button
                  onClick={goToNextPage}
                  style={{
                    marginLeft: "90vw",
                    marginTop: "-3vh",
                    position: "absolute",
                    background: "transparent",
                    border: "none",
                    transform: `scale(${scaleB})`,
                    opacity: elementsOpacity,
                    transition: "opacity 2s ease-in-out",
                  }}
                >
                  <img
                    src={arrowImage}
                    alt="Next"
                    onMouseEnter={handleMouseEnterR}
                    onMouseLeave={handleMouseLeaveR}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </button>
              </div>
              <img
                src={h6Images[language]}
                style={{
                  width: "30vw",
                  marginTop: "0vh",
                  marginLeft: "58vw",
                  zIndex: "101",
                  opacity: "elementsOpacity",
                  transition: "opacity 2s ease-in-out",
                }}
              />
            </div>
            <div
              onMouseEnter={() => setIsHoveredInfo2(true)}
              onMouseLeave={() => setIsHoveredInfo2(false)}
              style={{
                marginTop: "0vh",
                marginLeft: "58vw",
              }}
            >
              <img
                src={h1Gif}
                style={{
                  width: "30vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={info1Images[language]}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: isHoveredInfo2 ? 1 : 0,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
              <img
                src={infoico}
                style={{
                  width: "30vw",
                  position: "absolute",
                  marginLeft: "-30.1vw",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 1s ease-in-out",
                  border: "1px solid #dcb67a",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={carNav}
                style={{
                  width: "408px",
                  height: "23px",
                  marginLeft: "58vw",
                  marginTop: "2vh",
                  zIndex: "101",
                  opacity: elementsOpacity,
                  transition: "opacity 2s ease-in-out",
                }}
              />
              <button
                onClick={handleClickC}
                style={{
                  backgroundImage: `url(${naviconC})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "58vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
              <button
                onClick={handleClickL}
                style={{
                  backgroundImage: `url(${naviconL})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "51.5vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
              <button
                onClick={handleClickR}
                style={{
                  backgroundImage: `url(${naviconR2})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover", // Ensure the image covers the button area
                  backgroundPosition: "center", // Center the background image
                  border: "none", // Remove any border
                  cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                  width: "30px", // Set the width of the button
                  height: "23px", // Set the height of the button
                  outline: "none",
                  position: "absolute",
                  marginLeft: "64.5vw",
                  marginTop: "2vh",
                  zIndex: "105",
                }}
              ></button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="fullpage-background"
      style={{
        backgroundImage: `url(${supportImage})`,
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        zIndex,
        position: "relative", // Necessary for absolute positioning of the sliding image
      }}
    >
      {/* Sliding image */}
      <div style={imageStyle}>
        <div style={{ top: "50%" }}></div>
        <div className="carousel" style={{ opacity: elementsOpacity }}>
          {renderCarouselContent()}
        </div>
      </div>
      {/* Video container fades in based on isActive */}
      <div
        className="video-container"
        style={{
          opacity: style.opacity, // Use the same opacity transition as the background
          marginLeft: "-45%",
          marginTop: "15vh",
          transition: "opacity 4s ease",
          border: "2px solid #dcb67a",
          padding: "5px",
        }}
      >
        <iframe
          width="550"
          height="300"
          src="https://www.youtube.com/embed/7z0-WhURi0w"
          allowFullScreen
          style={{
            border: "1px solid #dcb67a",
            width: "35vw", // Ensure iframe is responsive
            height: "35vh",
          }}
        ></iframe>
      </div>
    </div>
  );
}

export default Features;

import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import communityImage from "../assets/community2.png";
import subImage from "../assets/subback1.png";
import enjoinImage from "../assets/join.png";
import enwishImage from "../assets/wishl.png";
import discImage from "../assets/disc.PNG";
import eswishImage from "../assets/essteam.png";
import esdiscImage from "../assets/esdiscord.png";
import frwishImage from "../assets/frwish.png";
import frdiscImage from "../assets/frdisc.png";
import dewishImage from "../assets/dewish.png";
import dediscImage from "../assets/dedisc.png";
import ptwishImage from "../assets/ptwish.png";
import ptdiscImage from "../assets/ptdisc.png";
import zhwishImage from "../assets/zhwish.png";
import zhdiscImage from "../assets/zhdisc.png";
import jawishImage from "../assets/jawish.png";
import jadiscImage from "../assets/jadisc.png";
import kowishImage from "../assets/kowish.png";
import kodiscImage from "../assets/kodisc.png";
import widgImage from "../assets/ktkWidg.PNG";
import "../App.css";
import "./Community.css";

const discImages = {
  en: enjoinImage,
  es: esdiscImage,
  fr: frdiscImage,
  de: dediscImage,
  pt: ptdiscImage,
  zh: zhdiscImage,
  ja: jadiscImage,
  ko: kodiscImage,
};

const wishImages = {
  en: enwishImage,
  es: eswishImage,
  fr: frwishImage,
  de: dewishImage,
  pt: ptwishImage,
  zh: zhwishImage,
  ja: jawishImage,
  ko: kowishImage,
};

const translations = {
  en: {
    subscribeP: "Subscribe to playtest our demo",
    first:
      "Be the first to access our demo when it's available, and stay up to date with the latest news and announcements.",
    privacy:
      "By submitting your email above, you confirm that you are at least 18 years of age and you acknowledge and consent to our privacy policy.",
    subscribe: "Subscribe",
    email: "Enter your email",
  },
  es: {
    subscribeP: "Suscríbete para probar nuestra demo",
    first:
      "Sé el primero en acceder a nuestra demo cuando esté disponible y mantente al día con las últimas noticias y anuncios.",
    privacy:
      "Al enviar tu correo electrónico arriba, confirmas que tienes al menos 18 años de edad y reconoces y consientes nuestra política de privacidad.",
    subscribe: "Suscribirse",
    email: "Ingresa tu correo electrónico",
  },
  fr: {
    subscribeP: "Abonnez-vous pour tester notre démo",
    first:
      "Soyez le premier à accéder à notre démo lorsqu'elle sera disponible et restez à jour avec les dernières nouvelles et annonces.",
    privacy:
      "En soumettant votre e-mail ci-dessus, vous confirmez que vous avez au moins 18 ans et vous reconnaissez et consentez à notre politique de confidentialité.",
    subscribe: "S'abonner",
    email: "Entrez votre email",
  },
  de: {
    subscribeP: "Abonnieren Sie, um unsere Demo zu testen",
    first:
      "Seien Sie der Erste, der Zugang zu unserer Demo erhält, wenn sie verfügbar ist, und bleiben Sie auf dem Laufenden mit den neuesten Nachrichten und Ankündigungen.",
    privacy:
      "Indem Sie Ihre E-Mail oben einreichen, bestätigen Sie, dass Sie mindestens 18 Jahre alt sind und Sie erkennen unsere Datenschutzrichtlinie an und stimmen ihr zu.",
    subscribe: "Abonnieren",
    email: "Geben Sie Ihre E-Mail ein",
  },
  pt: {
    subscribeP: "Inscreva-se para testar nossa demonstração",
    first:
      "Seja o primeiro a acessar nossa demo quando estiver disponível e fique por dentro das últimas notícias e anúncios.",
    privacy:
      "Ao submeter seu e-mail acima, você confirma que tem pelo menos 18 anos de idade e reconhece e consente com nossa política de privacidade.",
    subscribe: "Inscreverse",
    email: "Digite seu email",
  },
  zh: {
    subscribeP: "订阅以试玩我们的演示",
    first: "成为第一个在可用时访问我们演示的人，并随时了解最新新闻和公告。",
    privacy:
      "通过上述提交您的电子邮件，您确认您至少已满18岁，并且您承认并同意我们的隐私政策。",
    subscribe: "訂閱",
    email: "输入您的电子邮件",
  },
  ja: {
    subscribeP: "デモをプレイテストするために購読する",
    first:
      "利用可能になったときに最初にデモにアクセスし、最新のニュースやアナウンスメントを常に把握してください。",
    privacy:
      "上記のメールを送信することにより、あなたは少なくとも18歳であり、当社のプライバシーポリシーに同意し、それを認識することを確認します。",
    subscribe: "購読する",
    email: "メールアドレスを入力してください",
  },
  ko: {
    subscribeP: "데모 플레이테스트를 구독하세요",
    first:
      "사용 가능할 때 데모에 가장 먼저 접근하고 최신 뉴스와 공지사항을 최신 상태로 유지하세요.",
    privacy:
      "위의 이메일을 제출함으로써, 당신은 적어도 18세 이상임을 확인하며, 우리의 개인정보 보호정책을 인지하고 동의합니다.",
    subscribe: "구독하기",
    email: "이메일을 입력하세요",
  },
};

function Community({ isActive, language }) {
  const [scale, setScale] = useState(1);
  const [style, setStyle] = useState({
    opacity: 0,
    filter: "blur(10px)",
  });
  const [showSubscribeSection, setShowSubscribeSection] = useState(false);
  const [elementsOpacity, setElementsOpacity] = useState(0); // New state for managing opacity of new elements
  const [isChecked, setIsChecked] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Check if the consent checkbox is checked
    const isConsentChecked =
      form.current.querySelector("#consentCheckbox").checked;

    if (!isConsentChecked) {
      // Inform the user they need to agree to the privacy policy
      alert("Please agree to the privacy policy before subscribing.");
      return;
    }

    // Proceed with sending the email if the checkbox is checked
    emailjs
      .sendForm(
        "service_yhf875l",
        "template_sq0rogb",
        form.current,
        "AxqFPXpmXBtCQ-cs4"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  useEffect(() => {
    if (isActive) {
      setStyle({
        opacity: 1,
        filter: "blur(0px)",
        transition: "opacity 1s ease-in-out, filter 1s ease-in-out",
      });
      // Delay the appearance of the subscribe section and new elements
      setTimeout(() => {
        setShowSubscribeSection(true);
        setElementsOpacity(1); // Make new elements visible
      }, 700);
    } else {
      setStyle({
        opacity: 0,
        filter: "blur(10px)",
        transition: "opacity 2s ease-in-out, filter 2s ease-in-out",
      });
      setTimeout(() => {
        setShowSubscribeSection(false);
        setElementsOpacity(0); // Make new elements visible
      }, 700);
    }
  }, [isActive]);

  useEffect(() => {
    const calculateScale = () => {
      if (window.innerWidth > 1920) {
        setScale(1);
      } else {
        const scaleValue = window.innerWidth / 1080;
        setScale(scaleValue < 0.9 ? scaleValue : 0.9);
      }
    };

    calculateScale(); // Initial scale calculation.
    window.addEventListener("resize", calculateScale); // Adjust scale on window resize.

    return () => window.removeEventListener("resize", calculateScale); // Cleanup listener.
  }, []);

  return (
    <div
      className="fullpage-background"
      style={{
        backgroundImage: `url(${communityImage})`,
        ...style,
        zIndex: isActive ? 100 : 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      {/* Apply dynamic styles based on elementsOpacity */}
      <div
        style={{
          opacity: elementsOpacity,
          transition: "opacity 2s ease-in-out",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={discImages[language]} alt="Join Us" className="join" />
        <a
          href="https://discord.gg/5QvZjG5QXe"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={discImage} alt="Discord" className="discord" />
        </a>
        <img
          src={wishImages[language]}
          alt="Wishlist on Steam"
          className="wishlist"
        />
        <div
          className="iframe-container"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <iframe
            src="https://store.steampowered.com/widget/2803290/"
            frameborder="0"
            className="iframe"
            style={{
              transform: `scale(${scale})`,
            }}
          ></iframe>
        </div>
      </div>

      {showSubscribeSection && (
        <div
          className="subscribe-section"
          style={{
            backgroundImage: `url(${subImage})`,
            // Add the rest of your styling here
          }}
        >
          <div className="subscribe-content">
            <div className={`${language}subscribe-header`}>
              {translations[language]["subscribeP"]}
            </div>
            <div className={`${language}subscribe-body`}>
              {translations[language]["first"]}
            </div>
          </div>
          <form ref={form} className="subscribe-form" onSubmit={sendEmail}>
            <div>
              <input
                type="email"
                placeholder={translations[language]["email"]}
                name="user_email"
                required
              />

              <button type="submit">
                {translations[language]["subscribe"]}
              </button>
            </div>
            <div className="checkbox-section">
              <input
                type="checkbox"
                id="consentCheckbox"
                name="consent"
                hidden
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                required
              />

              <label
                htmlFor="consentCheckbox"
                className={`custom-checkbox ${isChecked ? "checked" : ""}`}
              ></label>

              <label htmlFor="consentCheckbox" className="consent-text">
                <div className={`${language}policy`}>
                  {translations[language]["privacy"]}
                </div>
              </label>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Community;

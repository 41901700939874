import React, { useEffect, useState } from "react";
import rewardsImage from "../assets/rewardsImage.png";
import supback from "../assets/supback.png";
import H1 from "../assets/siteH1.png";
import H2 from "../assets/siteH2.png";
import H3 from "../assets/ktkh7.png";
import patreon from "../assets/patreon6.png";
import patreonT from "../assets/patreonText.png";

import espatreon from "../assets/espatreon.png";
import frpatreon from "../assets/frpatreon.png";
import depatreon from "../assets/depatreon.png";
import ptpatreon from "../assets/ptpatreon.png";
import zhpatreon from "../assets/zhpatreon.png";
import japatreon from "../assets/japatreon.png";
import kopatreon from "../assets/kopatreon.png";

import esph from "../assets/esph.png";
import frph from "../assets/frph.png";
import deph from "../assets/deph.png";
import ptph from "../assets/ptph.png";
import zhph from "../assets/zhph.png";
import japh from "../assets/japh.png";
import koph from "../assets/koph.png";

import "../App.css";

const translations = {
  en: {
    s1: "👑 Supporting development on Patreon earns you exclusive rewards! 👑",
    s2: "LIMITED TIME OFFER - Support us now and get a FREE copy of our other game 'Kings Arena' as well as a copy of 'Kill The King' upon release!",
    patreon: patreon,
    H1: H1,
  },
  es: {
    s1: "👑 ¡Apoyar el desarrollo en Patreon te otorga recompensas exclusivas! 👑",
    s2: "OFERTA POR TIEMPO LIMITADO - ¡Apóyanos ahora y obtén una copia GRATIS de nuestro otro juego 'Kings Arena', así como una copia de 'Kill The King' al lanzamiento!",
    patreon: espatreon,
    H1: esph,
  },
  fr: {
    s1: "👑 Soutenir le développement sur Patreon vous donne droit à des récompenses exclusives ! 👑",
    s2: "OFFRE LIMITÉE DANS LE TEMPS - Soutenez-nous maintenant et obtenez une copie GRATUITE de notre autre jeu 'Kings Arena' ainsi qu'une copie de 'Kill The King' à sa sortie !",
    patreon: frpatreon,
    H1: frph,
  },
  de: {
    s1: "👑 Die Unterstützung der Entwicklung auf Patreon bringt dir exklusive Belohnungen! 👑",
    s2: "ZEITLICH BEGRENZTES ANGEBOT - Unterstütze uns jetzt und erhalte eine KOSTENLOSE Kopie unseres anderen Spiels 'Kings Arena' sowie eine Kopie von 'Kill The King' bei Veröffentlichung!",
    patreon: depatreon,
    H1: deph,
  },
  pt: {
    s1: "👑 Apoiar o desenvolvimento no Patreon te dá recompensas exclusivas! 👑",
    s2: "OFERTA POR TEMPO LIMITADO - Apoie-nos agora e ganhe uma cópia GRATUITA do nosso outro jogo 'Kings Arena', assim como uma cópia de 'Kill The King' no lançamento!",
    patreon: ptpatreon,
    H1: ptph,
  },
  zh: {
    s1: "👑 在Patreon上支持开发可以获得独家奖励！👑",
    s2: "限时优惠 - 现在支持我们，免费获取我们另一款游戏'Kings Arena'的副本以及'Kill The King'发布时的副本！",
    patreon: zhpatreon,
    H1: zhph,
  },
  ja: {
    s1: "👑 Patreonでの開発支援は、あなたに独占的な報酬をもたらします！👑",
    s2: "期間限定オファー - 今すぐ私たちをサポートし、リリース時に'Kill The King'のコピーと共に、私たちの別のゲーム'Kings Arena'の無料コピーを手に入れよう！",
    patreon: japatreon,
    H1: japh,
  },
  ko: {
    s1: "👑 Patreon에서 개발 지원을 하면 독점 보상을 받을 수 있습니다! 👑",
    s2: "한정 시간 제공 - 지금 우리를 지원하고 'Kings Arena'의 무료 복사본과 출시시 'Kill The King'의 복사본을 받으세요!",
    patreon: kopatreon,
    H1: koph,
  },
};

function Rewards({ isActive, zIndex, language }) {
  const [scale, setScale] = useState(1);
  const [style, setStyle] = useState({
    opacity: 0,
    filter: "blur(10px)",
  });

  useEffect(() => {
    if (isActive) {
      setStyle({
        opacity: 1,
        filter: "blur(0px)",
        transition: "opacity 2s ease-in-out, filter 2s ease-in-out",
      });
    } else {
      setStyle({
        opacity: 0,
        filter: "blur(10px)",
        transition: "opacity 2s ease-in-out, filter 2s ease-in-out",
      });
    }
  }, [isActive]);

  useEffect(() => {
    const calculateScale = () => {
      const scaleValue = window.innerWidth / 1080; // Assuming 1920 is the base width you want to scale from.
      setScale(scaleValue < 0.9 ? scaleValue : 0.9); // Prevent scaling above 1.
    };

    calculateScale(); // Initial scale calculation.
    window.addEventListener("resize", calculateScale); // Adjust scale on window resize.

    return () => window.removeEventListener("resize", calculateScale); // Cleanup listener.
  }, []);

  return (
    <div
      className="fullpage-background"
      style={{
        backgroundImage: `url(${rewardsImage})`,
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        zIndex,
      }}
    >
      <div
        className="subback"
        style={{
          backgroundImage: `url(${supback})`,
          backgroundImage: `url(${supback})`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center-align the flex items horizontally
          justifyContent: "center", // Center-align the flex items vertically (since it's column direction)
          transform: `scale(${scale})`, // Apply scale
          transformOrigin: "top center",
        }}
      >
        <img
          src={translations[language]["H1"]}
          alt="Wishlist on Steam"
          className="shi1"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p className="sh1">{translations[language]["s1"]}</p>
          <p className="sh2">{translations[language]["s2"]}</p>
        </div>

        <div
          className="patreon-button-container"
          style={{ position: "relative" }}
        >
          <a
            href="https://www.patreon.com/KillTheKing"
            className="patreon-button"
            style={{
              background: `url(${translations[language]["patreon"]}) no-repeat center center`,
            }}
          >
            {/* <img src={patreonT} alt="Patreon Text" className="patreon-text" /> */}
          </a>
        </div>

        <div
          style={{
            display: "flex", // Enables flexbox layout
            justifyContent: "center", // Centers the iframes horizontally
            alignItems: "center", // Aligns the iframes vertically in the center
            height: "30vh",
            marginTop: "-3vh",
          }}
        >
          <div
            style={{
              overflow: "clip",
              minWidth: "50%", // Original width of the iframe content
              height: "100%", // Original height of the iframe content
              transform: `scale(${scale})`, // Scales down the iframe content; adjust as necessary
            }}
          >
            <img src={H3} className="steamH" />
            <iframe
              src="https://store.steampowered.com/widget/2803290/"
              frameborder="0"
              style={{
                overflow: "clip",
                minWidth: "100%", // Original width of the iframe content
                height: "100%", // Original height of the iframe content
                transform: `scale(${scale})`, // Scales down the iframe content; adjust as necessary
              }}
            ></iframe>
          </div>
          <div
            style={{
              overflow: "clip",
              minWidth: "50%", // Original width of the iframe content
              height: "100%", // Original height of the iframe content
              transform: `scale(${scale})`, // Scales down the iframe content; adjust as necessary
            }}
          >
            <img src={H2} className="steamH" />
            <iframe
              src="https://store.steampowered.com/widget/2803290/"
              frameborder="0"
              style={{
                overflow: "clip",
                minWidth: "100%", // Original width of the iframe content
                height: "100%", // Original height of the iframe content
                transform: `scale(${scale})`, // Scales down the iframe content; adjust as necessary
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Rewards;

import React, { useState, useEffect } from "react";
import NavBar from "./components/NavBar/NavBar";
import Home from "./components/Home";
import Community from "./components/Community";
import Features from "./components/Features";
import Rewards from "./components/Rewards";
import Press from "./components/Press";
import "./App.css";

function App() {
  const [activeSection, setActiveSection] = useState("home");
  const [isScrolling, setIsScrolling] = useState(false); // State to manage scroll debounce
  const sections = ["home", "community", "features", "rewards", "press"];
  const [language, setLanguage] = useState("en");

  const handleLanguageChange = (language) => {
    setLanguage(language);
  };

  const handleScroll = (event) => {
    if (isScrolling) return; // If currently debouncing, ignore scroll event

    setIsScrolling(true); // Begin debounce period
    setTimeout(() => setIsScrolling(false), 1600); // Reset debounce state after 800ms, adjust as needed

    const currentIndex = sections.indexOf(activeSection);
    if (event.deltaY > 0) {
      // Scroll Down
      if (currentIndex < sections.length - 1) {
        const nextIndex = currentIndex + 1;
        setActiveSection(sections[nextIndex]);
      }
    } else {
      // Scroll Up
      if (currentIndex > 0) {
        const prevIndex = currentIndex - 1;
        setActiveSection(sections[prevIndex]);
      }
    }
  };

  useEffect(() => {
    const onWheel = (e) => handleScroll(e);
    window.addEventListener("wheel", onWheel, { passive: false });

    return () => window.removeEventListener("wheel", onWheel);
  }, [activeSection, isScrolling]); // Include isScrolling in the dependency array

  return (
    <div className="App">
      <NavBar
        currentSection={activeSection}
        onSectionChange={setActiveSection}
        onLanguageChange={handleLanguageChange}
        language={language}
      />

      <div className="section-container">
        <Home
          isActive={activeSection === "home"}
          zIndex={activeSection === "home" ? 100 : 1}
        />
        <Community
          isActive={activeSection === "community"}
          zIndex={activeSection === "community" ? 100 : 1}
          language={language}
        />
        <Features
          isActive={activeSection === "features"}
          zIndex={activeSection === "features" ? 100 : 1}
          language={language}
        />
        <Rewards
          isActive={activeSection === "rewards"}
          zIndex={activeSection === "rewards" ? 100 : 1}
          language={language}
        />
        <Press
          isActive={activeSection === "press"}
          zIndex={activeSection === "press" ? 100 : 1}
        />
      </div>
    </div>
  );
}

export default App;

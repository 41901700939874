import React, { useState } from "react";
import "./NavBar.css";
import navicon from "../../assets/navicon.png";
import steam from "../../assets/steam.png";
import youtube from "../../assets/youtube.png";
import twitter from "../../assets/twitter.png";
import discord from "../../assets/discord.png";
import localize from "../../assets/localize.png";

const translations = {
  en: {
    home: "Home",
    community: "Community",
    features: "Features",
    rewards: "Rewards",
    press: "Press",
    subscribe: "Subscribe",
  },
  es: {
    home: "Inicio",
    community: "Comunidad",
    features: "Características",
    rewards: "Recompensas",
    press: "Prensa",
    subscribe: "Suscribirse",
  },
  fr: {
    home: "Accueil",
    community: "Communauté",
    features: "Fonctionnalités",
    rewards: "Récompenses",
    press: "Presse",
    subscribe: "S'abonner",
  },
  de: {
    home: "Startseite",
    community: "Gemeinschaft",
    features: "Merkmale",
    rewards: "Belohnungen",
    press: "Presse",
    subscribe: "Abonnieren",
  },
  pt: {
    home: "Início",
    community: "Comunidade",
    features: "Recursos",
    rewards: "Recompensas",
    press: "Imprensa",
    subscribe: "Inscreverse",
  },
  zh: {
    home: "首頁",
    community: "社群",
    features: "功能",
    rewards: "獎勵",
    press: "新聞",
    subscribe: "訂閱",
  },
  ja: {
    home: "ホーム",
    community: "コミュニティ",
    features: "特徴",
    rewards: "報酬",
    press: "プレス",
    subscribe: "購読する",
  },
  ko: {
    home: "홈",
    community: "커뮤니티",
    features: "기능",
    rewards: "보상",
    press: "언론",
    subscribe: "구독하기",
  },
};

function NavBar({ currentSection, onSectionChange, onLanguageChange }) {
  const [language, setLanguage] = useState("en"); // Default language

  const handleChangeLanguage = (e) => {
    onLanguageChange(e.target.value);
    setLanguage(e.target.value);
  };

  return (
    <nav className="navbar">
      <a href="/" className="home-button">
        <img src={navicon} alt="Home" style={{ marginRight: "2vw" }} />
      </a>

      <div className="nav-content">
        <div className={`${language}-nav-links`}>
          {Object.keys(translations[language]).map((key) =>
            key !== "subscribe" ? (
              <div
                key={key}
                onClick={() => onSectionChange(key)}
                className={`${language}-nav-link ${
                  currentSection === key ? "active" : ""
                }`}
              >
                {translations[language][key]}
              </div>
            ) : null
          )}
        </div>
        <div className="social-links">
          <a
            className={`${language}local-button`}
            style={{
              background: `url(${localize}) no-repeat center center`,
              pointerEvents: "none",
            }}
          ></a>
          <select className="language-dropdown" onChange={handleChangeLanguage}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            <option value="de">Deutsch</option>
            <option value="pt">Português</option>
            <option value="zh">繁體中文</option>
            <option value="ja">日本語</option>
            <option value="ko">한국인</option>
          </select>
          <a
            href="https://store.steampowered.com/2803290/"
            className="link-button"
            style={{ background: `url(${steam}) no-repeat center center` }}
          ></a>
          <a
            href="https://www.youtube.com/@KillTheKingDev"
            className="link-button"
            style={{ background: `url(${youtube}) no-repeat center center` }}
          ></a>
          <a
            href="https://twitter.com/KillTheKing_Dev"
            className="link-button"
            style={{ background: `url(${twitter}) no-repeat center center` }}
          ></a>
          <a
            href="https://discord.gg/YctQhhN6EA"
            className="link-button"
            style={{ background: `url(${discord}) no-repeat center center` }}
          ></a>
          <button className="subscribe-button">
            {translations[language]["subscribe"]}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;

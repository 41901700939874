import React, { useEffect, useState } from "react";
import pressImage from "../assets/press.png"; // Adjust the path as needed

function Press({ isActive, zIndex }) {
  const [style, setStyle] = useState({
    opacity: 0,
    filter: "blur(10px)",
  });

  useEffect(() => {
    if (isActive) {
      setStyle({
        opacity: 1,
        filter: "blur(0px)",
        transition: "opacity 2s ease-in-out, filter 2s ease-in-out",
      });
    } else {
      setStyle({
        opacity: 0,
        filter: "blur(10px)",
        transition: "opacity 2s ease-in-out, filter 2s ease-in-out",
      });
    }
  }, [isActive]);

  return (
    <div
      className="fullpage-background"
      style={{
        backgroundImage: `url(${pressImage})`,
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        zIndex,
      }}
    >
      {/* Community content */}
    </div>
  );
}

export default Press;

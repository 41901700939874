import React, { useEffect, useState } from "react";
import homeImage from "../assets/home7.gif"; // Make sure the path is correct
import titleImage from "../assets/logo.png";
import demonImage from "../assets/demonpng.png";

// Assuming zIndex is passed as a prop now
function Home({ isActive, zIndex }) {
  const [scale, setScale] = useState(1);
  const [opacity, setOpacity] = useState(0);
  const [style, setStyle] = useState({
    opacity: 0,
    filter: "blur(10px)",
  });

  useEffect(() => {
    const calculateScale = () => {
      const scaleValue = window.innerWidth / 1080; // Assuming 1920 is the base width you want to scale from.
      setScale(scaleValue < 1 ? scaleValue : 1); // Prevent scaling above 1.
    };

    calculateScale(); // Initial scale calculation.
    window.addEventListener("resize", calculateScale); // Adjust scale on window resize.

    return () => window.removeEventListener("resize", calculateScale); // Cleanup listener.
  }, []);

  const [elementsOpacity, setElementsOpacity] = useState(0); // New state for managing opacity of new elements

  useEffect(() => {
    if (isActive) {
      setStyle({
        opacity: 1,
        filter: "blur(0px)",
        transition: "opacity 1s ease-in-out, filter 1s ease-in-out",
      });
      // Delay the appearance of the subscribe section and new elements
      setTimeout(() => {
        setElementsOpacity(1); // Make new elements visible
      }, 700);
    } else {
      setStyle({
        opacity: 0,
        filter: "blur(10px)",
        transition: "opacity 2s ease-in-out, filter 2s ease-in-out",
      });
      setElementsOpacity(0); // Reset opacity when not active
    }
  }, [isActive]);

  return (
    <div
      className="fullpage-background"
      style={{
        backgroundImage: `url(${homeImage})`,
        ...style,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        width: "100vw",
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: "7vh",
        zIndex, // Apply the zIndex dynamically here
      }}
    >
      <div>
        <img
          src={titleImage}
          alt="Title"
          style={{
            opacity: elementsOpacity,
            position: "relative",
            transition: "opacity 4s ease",
            width: "90vh", // Adjust based on screen size, consider media queries or a more responsive unit
            maxWidth: "70vw", // Prevent the image from being too large on bigger screens
            transform: "translateY(7vh)", // Adjust if necessary, removed the -270px for consistency
            marginTop: "-70vh", // Use viewport height percentage to adjust for different screen sizes
          }}
        />
      </div>
      <div
        className="video-container"
        style={{
          opacity: elementsOpacity,
          marginLeft: "50%",
          marginTop: "15vh",
          transition: "opacity 4s ease",
          border: "2px solid #dcb67a", // Add a border with the specified color
          padding: "5px",
          transform: `scale(${scale})`,
        }}
      >
        <iframe
          width="550vw"
          height="300vh"
          src="https://www.youtube.com/embed/7z0-WhURi0w"
          frameborder="0"
          allowFullScreen
          style={{
            border: "1px solid #dcb67a", // Add a border with the specified color

            width: "35vw", // Ensure iframe is responsive
            height: "35vh",
          }}
        ></iframe>
      </div>
      {/* Other content, including iframe, goes here */}
    </div>
  );
}

export default Home;
